.ball1,
.ball2,
.ball3,
.ball1-hover,
.ball2-hover,
.ball3-hover {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    float: left;
    margin: 0 3px;
}

.ball1 {
    /* border:2px solid white;  */
    background-color: white;
    -webkit-animation: single1ball1anim 2s infinite linear;
    animation: single1ball1anim 2s infinite linear;
    transition: 0.3s;
}

.ball1-hover {
    transition: 0.3s;
    background-color: grey;
    -webkit-animation: single1ball1anim 2s infinite linear;
    animation: single1ball1anim 2s infinite linear;
}

@-webkit-keyframes single1ball1anim {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    12.5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.5);
    }

    25%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes single1ball1anim {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    12.5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.5);
    }

    25%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.ball2 {
    background-color: white;
    -webkit-animation: single1ball2anim 2s infinite linear;
    animation: single1ball2anim 2s infinite linear;
}

.ball2-hover {
    transition: 0.3s;
    background-color: grey;
    -webkit-animation: single1ball2anim 2s infinite linear;
    animation: single1ball2anim 2s infinite linear;
}

@-webkit-keyframes single1ball2anim {
    0%,
    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    37.5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.5);
    }

    50%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes single1ball2anim {
    0%,
    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    37.5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.5);
    }

    50%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.ball3 {
    background-color: white;
    -webkit-animation: single1ball3anim 2s infinite linear;
    animation: single1ball3anim 2s infinite linear;
}

.ball3-hover {
    transition: 0.3s;
    background-color: grey;
    -webkit-animation: single1ball3anim 2s infinite linear;
    animation: single1ball3anim 2s infinite linear;
}

@-webkit-keyframes single1ball3anim {
    0%,
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    62.5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.5);
    }

    75%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes single1ball3anim {
    0%,
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    62.5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.5);
    }

    75%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
